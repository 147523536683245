export const en = {

    translations: {
        'statistika': 'Statistics',
        'atstovas': 'Representative',
        'atsake': 'Answered',
        'neatsake': 'Did not respond',
        'darbuotojai': 'Employees',
        "1. Socialinės distancijos vertinimas": "1. Assessment of social distance",
        "2. Požiūris apie įvairovę ir įtrauktį darbo vietoje": "2. Views on diversity and inclusion in the workplace",
        "3. Įdarbinimo vertinimas": "3. Hiring assessment",
        "4. Įvairovės ir įtraukties vertinimas darbo vietoje": "4. Evaluating diversity and inclusion in the workplace",
        "5. Darbuotojų gerovė": "5. Employee welfare",
        "6. Įtraukimas į sprendimų priėmimą": "6. Involvement in decision-making",
        "7. Socialinės ir demografinės charakteristikos": "7. Social and demographic characteristics",
        "8. Kiti klausimai (neprivaloma)": "8. Other questions",
        "2.1 Bendros strategijos": "2.1 General Strategies ",
        "2.2 Human resources strategies and recruitment procedures": "2.2 Human resources strategies and recruitment procedures",
        "2.3 Įtrauki darbo vietos aplinka": "2.3 Inclusive workplace environment",
        "2.4 Organizacijos charakteristikos ir duomenų kaupimas": "2.4 Company characteristics and data collection",
        "1.1: Ar sutiktumėte savo kaimynystėje gyventi su žemiau išvardintais žmonėmis?": "1.1: Would you agree to live in your neighborhood with the people listed below?",
        "1.2: Ar sutiktumėte dirbti toje pačioje įmonėje su žemiau išvardintais žmonėmis?": "1.2: Would you agree to work in the same organization with the people listed below? ",
        "2.1: Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus pagal:": "2.1: Do you think your team is diverse?",
        "3.1: Ar darbo pokalbio metu įmonė Jūsų klausė apie:": "3.1: Questions during a job interview",
        "3.2: Jeigu dabartinis darbdavys ieškotų darbuotojų ir turėtų du kandidatus su vienoda patirtimi ir kvalifikacijomis, kurie iš šių kriterijų galėtų daryti įtakos sprendimui dėl vieno arba kito kandidato pasirinkimo?": "3.2: Have you experienced discrimination or been treated unfairly at your current workplace?",
        "4.1: Ar darbovietėje girdėjote nemalonių komentarų ar juokelių apie save arba kitus asmenis?": "4.1: Have you heard unpleasant comments or jokes about yourself or others in the workplace?",
        "4.2: Kaip Jūs vertinate šias hipotetines situacijas:": "4.2: Assessment of hypothetical situations",
        "4.3: Ar patyrėte diskriminaciją arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:": "4.3: Have you experienced discrimination or been treated unfairly at your current workplace?",
        "4.4: Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami dėl savo:": "4.4: Do you know a colleague who has been discriminated against in the workplace?",
        "4.5: Jei Jūs ar Jūsų kolega patirtų diskriminacijos darbo vietoje, ar jaustumėtės saugus  apie tai papasakoti kolegoms?": "4.5: If you or your colleague experienced discrimination in the workplace, would you feel safe to tell colleagues about it? ",
        "4.6: Ar Jūsų darbo vietoje yra saugus pranešimo kanalas?": "4.6: Is there a secure communication channel at your workplace?",
        "4.7:  Jei Jūs ar Jūsų kolega patirtų diskriminaciją darbo vietoje, ar norėtumėte apie tai pranešti?": "4.7: If you or your colleague experience discrimination in the workplace, would you like to report it?",
        "5.1: Kiek žinote apie iššūkius, su kuriais susiduria gyventojai, kurie yra:": "5.1: How much do you know about the challenges different populations face?",
        "5.2: Prašome įvertinti kiekvieną klausimą apie Jūsų įmonę naudodami skalę.": "5.2: Employee well-being at work",
        "5.3: Prašome įvertinti kiekvieną klausimą apie Jūsų įmonę naudodami skalę.": "5.3: Workplace assessment",
        "5.4:  Ar dabartinėje darbo vietoje teko patirti patyčių ar priekabiavimo?": "5.4: Have you experienced bullying or harassment at your current workplace?",
        "6.1: Prašome įvertinti kiekvieną klausimą apie Jūsų įmonę naudodami skalę.": "6.1: Involvement of employees in decision-making",
        "2.1.1: Ar turite Įvairovės ir įtraukties (Į&Į) strategiją?": "2.1.1: Do you have a Diversity and Inclusion (D&I) strategy? ",
        "2.1.2: Ar per paskutinius 12 mėnesių viešai skelbėte vadovo/-ės ar valdybos pirmininkės/-opoziciją, palaikančią įvairovę ir įtrauktį?": "2.1.2: In the past 12 months, have you made any public announcements about diversity and inclusion by your director or board chair?",
        "2.1.3: Ar Jūsų organizacija priklauso bent vienai nacionalinei ar tarptautinei iniciatyvai, kuri skatina Į&Į arba verslo etiką (pabrėžiant lygias galimybes)?": "2.1.3: Does your organization belong to at least one national or international initiative that promotes D&i or business ethics (emphasizing equal opportunities)?",
        "2.1.4: Kokiais kanalais galėtų naudotis asmenys, norintys pranešti apie galimai patirtądiskriminaciją darbe arba darbo pokalbių metu?": "2.1.4: What channels can be used by persons who wish to report possible discrimination at work or during job interviews?",
        "2.1.5: Ar proaktyviai informuojate savo darbuotojus apie pranešimo kanalus ir užtvirtinate,kad pranešimai yra laukiami?": "2.1.5: Do you proactively inform your employees about reporting channels and assure that reports are welcome?",
        "2.1.6: Prašome pažymėti prie kiekvieno teiginio:": "2.1.6: Please tick next to each statement:",
        "2.1.7: Kaip įmonės viduje atliekate Į&Į stebėseną?": "2.1.7: How do you monitor D&I within the organization?",
        "2.2 Žmogiškųjų išteklių strategijos ir įdarbinimo procedūros": "2.2 Human resources strategies and recruitment procedures",
        "2.2.1: Kokių žingsnių imatės siekiant užtikrinti Į&Į įdarbinimo metu?": "2.2.1: What steps do you take to ensure D&I during recruitment?",
        "Organizacijos atstovės/-o apklausa": "Survey for the representative of the organization",
        "Darbuotojų apklausa": "Employee survey",
        "apklausa_atlikta": "The survey is done",
        "Kviečiame atsakyti į keletą klausimų apie Jūsų atstovaujamos organizacijos sprendimus, susijusius su įvairovės valdymu ir įtrauktimi. Tikimės, kad šis vertinimas bei darbuotojų apklausa padės kurti dar geresnę, saugesnę ir įtraukesnę darbo aplinką Jūsų kolektyvui. \n\nApklausai užpildyti preliminariai reikės iki 15 minučių.\n\nŠią apklausą kūrė ir ja su Jūsų organizacija dalinasi Lietuvos Įvairovės Chartija. Dėl klausimų ar komentarų kviečiame rašyti diversity@diversity.lt": "We invite you to answer a few questions about your organization's decisions related to diversity management and inclusion. We hope that this assessment and employee survey will help create an even better, safer and more inclusive work environment for your team.",
        "Nera_ats": "There are no answers",
        "Nera_atsakymu": "There are no answers",
        "Prašome atsakyti į klausimą": "Please answer the question",
        "amziusLabel": "Age",
        "apklausos_label": "Surveys",
        "arIstrintApklausa": "Are you sure you want to delete the survey?",
        "arIstrintProfili": "Are you sure you want to delete organization profile?",
        "atlikti_apklausa": "Collect responses",
        "balas": "Score",
        "balas_palygintas": "The score is compared with the Lithuanian average",
        "bendrasApklausosIvertinimas": "Overall survey rating (out of 10 possible)",
        "bendrasDarbuotojuApklausosIvertinimas": "Evaluation of employee survey",
        "bendrasIvertinimas": "Overall survey rating",
        "bendrasOrganizacijosAtstovu": "Evaluation of the survey for the organization's representatives",
        "create": "+ New survey",
        "create_imone": "+ Add organization",
        "darbuotojų_apklausa": "Diversity and Inclusion Assessment",
        "esu_organizacijos_atstovas": "I am an authorized representative of the organization and can create an account on behalf of the organization",
        "filtruoti": "Filter",
        "formosPavadinimas": "Survey title",
        "imones_label": "Organizations",
        "imones_pav": "Organization name",
        "issaugoti": "Save",
        "issilavinimaiLabel": "Education",
        "iveskiteImone": "Enter organization name",
        "iveskitePass": "Password must be at least 6 characters long",
        "ivestiName": "Enter first and last name",
        "kompiuteriaiLabel": "Do you use a personal computer at work?",
        "kopijuoti": "Copy",
        "kopijuoti_nuoroda": "Copy the link",
        "laikaiLabel": "For how long is working",
        "laukia_patvirtinimo": "Waiting for confirmation",
        "login_email": "Email",
        "login_pass": "Password",
        "login_pass_pakartot": "Repeat the password",
        "lytysLabel": "Gender",
        "ne": "No",
        "nebegalioja": "Invalid",
        "negaliosLabel": "Do you have a disability?",
        "nepatvirtinta": "Unconfirmed",
        "neteisingasEmail": "Invalid email",
        "neteisingasTel": "Incorrect phone no. (+…)",
        "neteisingiDuomenys": "Invalid login data",
        "new_login_pass": "New password",
        "orientacijosLabel": "Sexual orientation",
        "LGBTLabel": "Do you belong to LGBT+ community?",
        "padetysLabel": "Marital status",
        "pamirsai_pass": "Forgot password?",
        "pareigomsLabel": "Position in the organization",
        "patvirtinta": "Confirmed",
        "patvirtinti_reg": "Confirm registration",
        "paviesinta": "Published",
        "Paviešinta": "Published",
        "perziureti_rezultatus": "View results",
        "pildytojuTipaiLabel": "Choose",
        "prenumeratos_label": "Subscription expiration date",
        "prisijungti": "Login",
        "prisiminti": "Remind",
        "profilio_redagavimas": "Editing a profile",
        "profilis": "Profile",
        "redaguoti": "Edit",
        "registruoti": "Register",
        "registruotis": "Register",
        "religijosLabel": "Are you religious?",
        "rezultataiRezultatai": "Results",
        "rezultataiTendencijos": "Score",
        "rodoma": "Showing:",
        "sekminga_reg": "Registration successful!",
        "sekminga_reg_txt1": "We will activate your profile or contact you within 3 working days. If you have any questions, we invite you to write us an e-mail: diversity@diversity.lt",
        "slaptazodzio_priminimas": "Password reminder",
        "slaptazodzio_priminimas_txt": "To remember your password, enter your email address you use to sign in",
        "statusas_label": "Status",
        "sukurti_apklausa": "Create a survey",
        "sukurti_imone": "Organization's registration",
        "susipazinau_su_taisyklem": "I have read the privacy policy and the rules",
        "taip": "Yes",
        "telNr": "Tel. no.",
        "tipas_label": "Form type",
        "trinti": "Delete",
        "vardas_pavarde": "Name Surname",
        "visosImonesLabel": "Choose an organization",
        "iveskite_nauja_pass": 'Enter a new password',
        "ExportTable": "table",
        "Nepaviešinta": "Not published",
        "max3filtrai": "Select no more than 3 filters",
        "diskriminacija": "Employees who find it difficult to recognize the potential",
        "slaptazodisPakeistas": "Password changed",
        "ivairove_patvirtintas_acc": "User is verified",
        "pp": 'Privacy policy',
        "pt": 'Terms of Service',
        'iveskiteImoneMin': 'Incorrect company name',
        "atliko": "Finished surveys: {{kiek}}",
        "accComfirmedError": "User not confirmed",
        "accValidToError": "Subscription has expired",
        "grafikai": {
            komentaras: "Comments",
            rodyti: "Show",
            paslepti: "Hide"
        },
        rezultatai: {
            title: "Diversity and inclusion score:",
            balai: "{{balas}} out of 10",
            share: "Score visual",
        },
        amziaiText: ['18-34 years', '35-54 years', '55-74 years', '75 years or older', "I won’t say"],
        issilavinimaiText: ['Primary', 'Secondary', 'Higher', 'Other'],
        kompiuteriaiText: ['I have a personal computer for work', 'I work on shared computers', "I don't work with a computer"],
        laikaiText: ['Up to 1 year', '1-5 years', '5-10 years', 'Over 10 years'],
        linkaiAdminText: ['Score', 'Results', 'Surveys', 'Organizations'],
        linkaiNeAdminText: ['Score', 'Results', 'Surveys',],
        lytysText: ['Man', 'Woman', 'Other', 'I won’t say'],
        negaliosText: ['Yes', 'No', 'I won’t say'],
        orientacijosText: ['Asexual', 'Bisexual', 'Homosexual', 'Heterosexual', 'Other', 'I won’t say'],
        LGBTText: ['Yes', 'No', 'I won’t say'],
        pildytojuTipaiText: ['Employee survey', "Survey for the representative of the organization", 'Overall result'],
        pildytojuTipaiText2: ['Employee survey', "Survey for the representative of the organization"],
        'Įmonės forma': "Survey for the representative of the organization",
        'Darbuotojo forma': 'Employee survey',
        'All': 'Overall result',

        tipas_text: ['Employee survey', "Survey for the representative of the organization"],
        padetysText: ['Married', 'In partnership', 'Single', 'Divorced', 'I won’t say', 'Other'],
        pareigomsText: ['Top level manager', 'Mid-level manager', 'First line manager', 'Employee/specialist', 'Other'],
        religijosText: ['Yes', 'No', "I don't know", 'I won’t say'],
        statusas_text: ['Suspended', 'Not published', 'Published'],
        menuAdmin: ['Organizations', 'Forms editor', 'Results'],
        prenumeratos_options: ['One year', 'Unlimited', 'Suspended'],
        metai: "Year",

        //30.1
        'Vyras': 'Man',
        'Moteris': 'Woman',
        'Kita': 'Other',
        'Nesakysiu': "I won't say",
        "Lytis": "Gender",
        "Amzius": "Age",
        '18-34 metai': '18-34 years',
        '35-54 metai': '35-54 years',
        '55-74 metai': '55-74 years',
        '75 metai ar vyresnis': '75 years or older',
        'Nesakysiu': "I won't say",
        "Padetis": 'Family status',
        'Vedęs/Ištekėjusi': 'Married/Divorced',
        'Partnerystėje': 'In partnership',
        'Vienas (-a)': 'Single',
        'Išsiskyręs (-usi)': 'Separated',
        'Nesakysiu': "I won't say",
        'Kita': 'Other',
        'Kiek laiko': 'How long',
        'Iki 1 m.': 'Up to 1 m.',
        '1-5 m.': '1-5 m.',
        '5-10 m.': '5-10 m.',
        'Virš 10 m.': 'Over 10 m.',
        'Pareigos': 'Job title',
        'Aukščiausio lygio vadovas/ė': 'Top level manager',
        'Vidutinio lygio vadovas/ė': 'Middle level manager',
        'Pirmojo lygio vadovas/ė': 'First level manager',
        'Darbuotojas/ specialistė': 'Employee/ specialist',
        'Kita': 'Other',
        "Kompiuteris": "Computer",
        'Darbo reikmėms turiu asmeninį kompiuterį': 'I have a personal computer for work',
        'Dirbu prie bendro naudojimo kompiuterių': 'I work on shared computers',
        'Nedirbu su kompiuteriu': "I don't work with a computer",
        "Issilavinimas": "Education",
        'Pradinis': 'Primary',
        'Vidurinis': 'Secondary',
        'Aukštasis': 'Higher',
        'Kita': 'Other',
        'Religija': 'Religious',
        'Taip': 'Yes',
        'Ne': 'No',
        'Nežinau': "Don't know",
        'Nesakysiu': "I won't say",
        'Orientacija': 'Sexual orientation',
        'LGBT': 'Do you belong to LGBT+ community?',
        'Aseksualus': 'Asexual',
        'Biseksualus': 'Bisexual',
        'Homoseksualus': 'Homosexual',
        'Heteroseksualus': 'Heterosexual',
        'Kita': 'Other',
        'Nesakysiu': "I won't say",
        'Negalia': 'Disability',
        'Taip': 'Yes',
        'palyginus_su_vidurkiu': 'The score is compared with the average scores of other companies.',
        'atlikti_vertinima_label': "Collect responses",
        'atsisiusti_visus': 'JPG',
        'atidaryti_visus': 'Show all',
        'paslepti_visus': 'Hide all',
        'is_10_galimu': 'Out of 10 possible',


        //Taisyklės
        'pp1': 'Last updated: January 31, 2023',
        'pp2': 'This is our – tool’s evaluating diversity and inclusion (hereinafter referred to as the “Tool”) privacy policy, which applies to the processing of personal data of registered users (hereinafter referred to as “You”). The Tool’s team consists of employees of the public institution Lithuanian diversity charter (registration code 302877111, address Lukiškių str. 5-527, Vilnius). Association Lithuanian diversity charter is the controller of the data processed for the purposes of the Tool (hereinafter referred to as "We").',
        'pp3': 'The purpose of the tool is to assess the state of diversity and inclusion in your team. In order to achieve this goal, we must process the personal data that you provide when registering and using the Tool. ',
        'pp4': 'When processing Your personal data, we comply with the requirements of the General Personal Data Protection Regulation (hereinafter referred to as the “GDPR”), the Law on Legal Protection of Personal Data of the Republic of Lithuania, as well as other legislative acts concerning personal data handling.',
        'pp5': 'What services can You get in the Tool?',
        'pp6': 'We allow you to create an account (profile) in the Tool, in which you specify your name, surname, e-mail address, represented organization.',
        'pp7': "We allow you to use the questionnaires in the Tool, which you can share with your employees and invite them to confidentially answer the questions presented in the Tool. Employees' answers are saved and you can get acquainted with the personalized answers in the Tool.",
        'pp8': 'In the Tool, we allow you to use two questionnaires – one is intended for the representative of the organization, the other is intended for employees. The answers to both questionnaires are aggregated into a total score and presented in such a way that you can assess how the people working in your team feel, what their experiences are, and how prepared the organization you represent is to ensure an open and inclusive work environment.',
        'pp9': 'What data do We collect, store and use? ',
        'pp10': 'In order to provide the above services, You provide and We store the following information:',
        'pp11': 'Data category',
        'pp12': 'Data type',
        'pp13': 'Mandatory registration data (necessary to complete registration in the Tool)',
        'pp14':'Name;',
        'pp15':'Surname;',
        'pp16':'Email address;',
        'pp17':'Name of the represented organization',
        'pp18':'Access data',
        'pp19':'Unikalus Jūsų prieigos raktas, kuris sukuriamas, kai pirmą kartą užsiregistruojate Įrankyje. Šis prieigos raktas reikalingas tam, kad galėtumėte prisijungti prie paskyros, dalintis su darbuotojais apklausomis, pamatyti nuasmenintus apklausų rezultatus.',
        'pp20':'Connection details (After you share the link to the Tool survey with employees)',
        'pp21':'Answers to survey questions.',
        'pp22':'Password policy',
        'pp23':'When You create an account in the Tool, You choose a login password. The National Cyber Security Center recommends using strong passwords that are at least 12 characters long and consist of uppercase letters, lowercase letters, numbers, and special characters. It is recommended to use a unique password that is not used anywhere else.',
        'pp24':'Do not disclose Your account password to third parties. If You disclose Your password or any other account information to other persons, You will be responsible for all actions taken while using Your account.',
        'pp25':'Your password is the key to signing in to Your account. We do not accumulate Your passwords. By logging in to Your account, You can change and update Your data at any time. If You forget Your password, You can create a new one.',
        'pp26':'For what purposes and on what legal basis do we process Your personal information and for how long do we store it?',
        'pp27':'Purpose and circumstances of the data processing',
        'pp28':'Categories of data processed',
        'pp29':'Legal basis for data processing',
        'pp30':'Data retention period',
        'pp31':'To create Your profile and provide basic Tool services',
        'pp32':'Mandatory and optional registration data',
        'pp33':'Login key',
        'pp34':'Legitimate interest (Art. 6(1) (f) GDPR) to provide and receive services',
        'pp35':'Data is processed as long as Your account is active, i.e. until You delete it',
        'pp36':'Data analysis to improve Tool performance and user experience',
        'pp37':'Aggregated and anonymized technical data ',
        'pp38':'Aggregated and anonymised mandatory and optional registration data',
        'pp39':'Legitimate interest (Art. 6(1) (f) GDPR) to improve the services offered in the Tool and Your interest in receiving quality services',
        'pp40':'Anonymized (non-identifying Your identity) personal data may be stored for an unlimited period of time',
        'pp41':'Identify and address technical vulnerabilities in the Tool and ensure security requirements',
        'pp42':'Technical data',
        'pp43':'Other categories of data, depending on the nature of the incidents',
        'pp44':'Legitimate interest (Art. 6(1) (f) GDPR) to ensure security and prevent unfair practices by third parties',
        'pp45':'The data shall be processed until the technical problem or security incident is resolved and for 1 year after the end of the incident',
        'pp46':'Respond to inquiries and requests from Tool users',
        'pp47':'Correspondence or communications data',
        'pp48':'Legitimate interest (Art. 6(1) (f) GDPR) to ensure continuity of services and quality of provision',
        'pp49':'Legal obligation (Art. 6(1) (c) GDPR) to exercise the rights of data subjects under the GDPR',
        'pp50':'Data is stored for 6 months after the last response to Your request',
        'pp51':'Upon expiry of the specified data retention period, we will delete Your data, unless it is necessary for the fulfillment of legal obligations (e.g. to respond to legal claims) and regulatory requirements (e.g. to provide data to competent authorities on legal grounds).',
        'pp52':'With whom can we share Your personal data? ',
        'pp53':'We will not transfer Your personal data to third parties unless it is necessary to ensure the services we provide or required by law. For these reasons, Your personal data may be transferred to the following groups of recipients: ',
        'pp54':'Companies providing technical support and technical infrastructure services (e.g. data 	hosting and storage) for the Tool;',
        'pp55':'Facebook and Google, which provide sign-in plugin services. These companies may process Your data in third countries, therefore we recommend that You familiarize Yourself with the information provided by the following companies and the terms of standard contracts: ',
        'pp56':'Facebook',
        'pp57':'Google',
        'pp58':'Competent public authorities, but only if required by law or court decisions;',
        'pp59':'We may share aggregated and anonymized (which does not allow us to identify You as 	a person) information with sponsors specified in this Tool and a wider audience by 	informing them about the services provided in the Tool.',
        'pp60':'Your rights',
        'pp61':'When processing personal data, we guarantee Your rights under the GDPR and the Law on Legal Protection of Personal Data of the Republic of Lithuania. You have the following rights:',
        'pp62':'correct or supplement, adjust ',
        'pp63':'incorrect or inaccurate personal data about You. You can do this in the "Profile" section of the Tool;',
        'pp64':'delete Your account ',
        'pp65':'and thus destroy Your personal data. If You delete Your account, Your personal data will be deleted from the system, we will no longer have it and we will 	not be able to recover it. In this case, You will no longer be able to use the Tool and we 	will not be able to help Your organization to evaluate its status in the field of diversity and inclusion; ',
        'pp66':'get acquainted with Your data ',
        'pp67':'hat we have collected, in other words, to provide a copy of the data we hold about You. You can see Your registration details in the "Profile" 	section of the Tool;',
        'pp68':'request restriction of data processing ',
        'pp69':'– if You believe that we are processing Your data unlawfully or the data we hold about You is incorrect, You can request that we temporarily not use Your data. In such a case, until the issues of legality or fairness are resolved, we can only store the data, while other actions with it would require Your separate consent; ',
        'pp70':'request to object to the processing ',
        'pp71':'– if You believe that there are specific reasons why we should not process Your data, You can also contact us for these reasons and 	request that Your data not be processed; ',
        'pp72':'file a complaint with a supervisory authority ',
        'pp73':'- if You believe that we are using Your data unlawfully, You can also file a complaint with the data protection authority. In Lithuania, this is - State Data Protection Inspectorate ',
        'pp74':'You can submit a request for the exercise of Your rights and contact us by e-mail diversity@diversity.lt',
        'pp75':'Privacy Policy Updates',
        'pp76':'We may change this Privacy Policy from time to time to reflect the current situation. Once we will make changes, we will specify an update date at the top of this page. We recommend that You periodically review our security policy so that You know how we protect and process Your personal data.',
        'pp77':'I certify that I have all rights to create an account on behalf of an organisation and that all information I will provide is correct. The Company assumes full responsibility for the accuracy of the information provided.',
    }
}
