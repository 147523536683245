export const ru = {

  translations: {
    'statistika': 'Статистика',
    'atstovas': 'Представитель',
    'atsake': 'Отвечено',
    'neatsake': 'Не ответил',
    'darbuotojai': 'Сотрудники',
    "1. Socialinės distancijos vertinimas": "1. Оценка социальной дистанции",
    "2. Požiūris apie įvairovę ir įtrauktį darbo vietoje": "2. Отношение к разнообразию и интеграции на рабочем месте",
    "3. Įdarbinimo vertinimas": "3. Оценка найма",
    "4. Įvairovės ir įtraukties vertinimas darbo vietoje": "4. Оценка разнообразия и инклюзивности на рабочем месте",
    "5. Darbuotojų gerovė": "5. Благополучие сотрудников",
    "6. Įtraukimas į sprendimų priėmimą": "6. Включение в  принятии решений",
    "7. Socialinės ir demografinės charakteristikos": "7. Социально-демографические характеристики",
    "8. Kiti klausimai (neprivaloma)": "8. Другие вопросы",
    "2.1 Bendros strategijos": "2.1 Общие стратегии",
    "2.2 Human resources strategies and recruitment procedures": "2.2 Стратегии управления персоналом и процедуры найма",
    "2.3 Įtrauki darbo vietos aplinka": "2.3 Инклюзивная рабочая среда",
    "2.4 Organizacijos charakteristikos ir duomenų kaupimas": " 2.4 Характеристики организации и сбор данных",
    "1.1: Ar sutiktumėte savo kaimynystėje gyventi su žemiau išvardintais žmonėmis?": "1.1: Согласились бы Вы жить по соседству с людьми, перечисленными ниже?",
    "1.2: Ar sutiktumėte dirbti toje pačioje įmonėje su žemiau išvardintais žmonėmis?": "1.2: Согласились бы Вы работать в одной организации с нижеперечисленными людьми?",
    "2.1: Ar, Jūsų manymu, Jūsų kolektyvas yra įvairus pagal:": "2.1: Считаете ли Вы, что в Вашей команде есть разнообразие (гендерное/рассовое/культурное/религиозное)?",
    "3.1: Ar darbo pokalbio metu įmonė Jūsų klausė apie:": "3.1: Вопросы во время собеседования",
    "3.2: Jeigu dabartinis darbdavys ieškotų darbuotojų ir turėtų du kandidatus su vienoda patirtimi ir kvalifikacijomis, kurie iš šių kriterijų galėtų daryti įtakos sprendimui dėl vieno arba kito kandidato pasirinkimo?": "3.2: Сталкивались ли Вы с дискриминацией или несправедливым обращением на Вашем нынешнем рабочем месте?",
    "4.1: Ar darbovietėje girdėjote nemalonių komentarų ar juokelių apie save arba kitus asmenis?": "4.1:  Слышали ли вы неприятные комментарии или шутки о себе или других людях на рабочем месте?",
    "4.2: Kaip Jūs vertinate šias hipotetines situacijas:": "4.2: Оценка гипотетических ситуаций",
    "4.3: Ar patyrėte diskriminaciją arba su Jumis buvo elgiamasi nesąžiningai dabartinėje darbo vietoje dėl Jūsų:": "4.3: Сталкивались ли Вы с дискриминацией или несправедливым обращением на Вашем нынешнем рабочем месте?",
    "4.4: Ar žinote kolegą (-ę), kurie darbovietėje buvo diskriminuojami dėl savo:": "4.4: Вы знаете коллегу, который подвергался дискриминации на рабочем месте?",
    "4.5: Jei Jūs ar Jūsų kolega patirtų diskriminacijos darbo vietoje, ar jaustumėtės saugus  apie tai papasakoti kolegoms?": "4.5:  Если бы Вы или Ваш коллега столкнулись с дискриминацией на рабочем месте, не стеснялись бы Вы рассказать об этом своим коллегам?",
    "4.6: Ar Jūsų darbo vietoje yra saugus pranešimo kanalas?": "4.6:  Есть ли на Вашем рабочем месте безопасный  канал связи?",
    "4.7:  Jei Jūs ar Jūsų kolega patirtų diskriminaciją darbo vietoje, ar norėtumėte apie tai pranešti?": "4.7: Если Вы или Ваш коллега сталкиваетесь с дискриминацией на рабочем месте,  хотели ли Вы сообщить об этом?",
    "5.1: Kiek žinote apie iššūkius, su kuriais susiduria gyventojai, kurie yra:": "5.1: Что Вы знаете о проблемах, с которыми сталкиваются различные группы населения?",
    "5.2: Prašome įvertinti kiekvieną klausimą apie Jūsų įmonę naudodami skalę.": "5.2: Самочувствие сотрудников на работе",
    "5.3: Prašome įvertinti kiekvieną klausimą apie Jūsų įmonę naudodami skalę.": "5.3: Оценка рабочего места",
    "5.4:  Ar dabartinėje darbo vietoje teko patirti patyčių ar priekabiavimo?": "5.4: Сталкивались ли Вы с травлей или домогательствами на Вашем нынешнем рабочем месте?",
    "6.1: Prašome įvertinti kiekvieną klausimą apie Jūsų įmonę naudodami skalę.": "6.1: Вовлечение сотрудников в принятие решений",
    "2.1.1: Ar turite Įvairovės ir įtraukties (Į&Į) strategiją?": "2.1.1: Есть ли у Вас стратегия разнообразия и инклюзивности (Р&И)?",
    "2.1.2: Ar per paskutinius 12 mėnesių viešai skelbėte vadovo/-ės ar valdybos pirmininkės/-opoziciją, palaikančią įvairovę ir įtrauktį?": "2.1.2: Делали ли Вы за последние 12 месяцев какие-либо публичные заявления о разнообразии и инклюзивности со стороны вашего генерального директора или председателя правления?",
    "2.1.3: Ar Jūsų organizacija priklauso bent vienai nacionalinei ar tarptautinei iniciatyvai, kuri skatina Į&Į arba verslo etiką (pabrėžiant lygias galimybes)?": "2.1.3: Принадлежит ли Ваша организация хотя бы к одной национальной или международной инициативе, продвигающей предпринимательство или деловую этику (подчеркивая равные возможности)?",
    "2.1.4: Kokiais kanalais galėtų naudotis asmenys, norintys pranešti apie galimai patirtądiskriminaciją darbe arba darbo pokalbių metu?": "2.1.4: Какие каналы могут использовать лица, желающие сообщить о возможной дискриминации на работе или во время собеседований?",
    "2.1.5: Ar proaktyviai informuojate savo darbuotojus apie pranešimo kanalus ir užtvirtinate,kad pranešimai yra laukiami?": "2.1.5: Вы активно информируете  своих сотрудников о каналах сообщения и подтверждаете, что сообщения приветствуются?",
    "2.1.6: Prašome pažymėti prie kiekvieno teiginio:": "2.1.6: Пожалуйста, отметьте рядом с каждым утверждением:",
    "2.1.7: Kaip įmonės viduje atliekate Į&Į stebėseną?": " 2.1.7: Как Вы проводите мониторинг Р&И внутри организации?",
    "2.2 Žmogiškųjų išteklių strategijos ir įdarbinimo procedūros": "2.2 Стратегии управления персоналом и процедуры найма",
    "2.2.1: Kokių žingsnių imatės siekiant užtikrinti Į&Į įdarbinimo metu?": "2.2.1: Какие шаги Вы предпринимаете для обеспечения Р&И при найме?",
    "Darbuotojų apklausa": "Опрос сотрудников",
    "apklausa_atlikta": "Опрос сделан",
    "Organizacijos atstovės/-o apklausa": "Опрос для представителя организации",
    "Kviečiame atsakyti į keletą klausimų apie Jūsų atstovaujamos organizacijos sprendimus, susijusius su įvairovės valdymu ir įtrauktimi. Tikimės, kad šis vertinimas bei darbuotojų apklausa padės kurti dar geresnę, saugesnę ir įtraukesnę darbo aplinką Jūsų kolektyvui. \n\nApklausai užpildyti preliminariai reikės iki 15 minučių.\n\nŠią apklausą kūrė ir ja su Jūsų organizacija dalinasi Lietuvos Įvairovės Chartija. Dėl klausimų ar komentarų kviečiame rašyti diversity@diversity.lt": "Мы предлагаем Вам ответить на несколько вопросов о решениях в Вашей организации, относящихся к многообразию и инклюзивности. Мы надеемся, что эта оценка и опрос сотрудников помогут создать лучшую, более безопасную и инклюзивную рабочую среду для Вашей команды.",
    "Nera_ats": "Нет ответов",
    "Nera_atsakymu": "Нет ответов",
    "Prašome atsakyti į klausimą": "Пожалуйста, ответьте на вопрос",
    "amziusLabel": "Возраст",
    "apklausos_label": "Опросы",
    "arIstrintApklausa": "Вы уверены, что хотите удалить опрос?",
    "arIstrintProfili": "Вы уверены, что хотите удалить профиль организации?",
    "atlikti_apklausa": "Провести оценку",
    "balas": "Счет",
    "balas_palygintas": "Оценка сравнивается со средней по Литве.",
    "bendrasApklausosIvertinimas": "Общий рейтинг опроса (из 10 возможных)",
    "bendrasDarbuotojuApklausosIvertinimas": "Оценка опроса сотрудников",
    "bendrasIvertinimas": "Общий результат",
    "bendrasOrganizacijosAtstovu": "Оценка опроса представителей организации",
    "create": "+ Новый опрос",
    "create_imone": "+ Добавить организацию",
    "darbuotojų_apklausa": "Оценка разнообразия и инклюзивности",
    "esu_organizacijos_atstovas": "Я являюсь уполномоченным представителем организации и имею право создать учетную запись от имени организации.",
    "filtruoti": "Фильтр",
    "formosPavadinimas": "Название опроса",
    "imones_label": "Организации",
    "imones_pav": "Название организации",
    "issaugoti": "Сохранить",
    "issilavinimaiLabel": "Образование",
    "iveskiteImone": "Введите название организации",
    "iveskitePass": "Пароль должен содержать не менее 6 символов",
    "ivestiName": "Введите имя и фамилию",
    "kompiuteriaiLabel": "Используете ли Вы персональный компьютер для работы?",
    "kopijuoti": "Копировать",
    "kopijuoti_nuoroda": "Скопируйте ссылку",
    "laikaiLabel": "Как долго работает",
    "laukia_patvirtinimo": "Ожидание подтверждения",
    "login_email": "Электронная почта",
    "login_pass": "Пароль",
    "login_pass_pakartot": "Повторите пароль",
    "lytysLabel": "Пол",
    "ne": "Нет",
    "nebegalioja": "Недействителен",
    "negaliosLabel": "Вы - человек с ограниченными возможностями здоровья/человек с инвалидностью?",
    "nepatvirtinta": "Не подтверждено",
    "neteisingasEmail": "Неверный адрес электронной почты",
    "neteisingasTel": "Неверный номер телефона (+…)",
    "neteisingiDuomenys": "Неверные данные для входа",
    "new_login_pass": "Новый пароль",
    "orientacijosLabel": "Сексуальная ориентация",
    "LGBTLabel": "Вы принадлежите к сообществу ЛГБТ+",
    "padetysLabel": "Семейное положение",
    "pamirsai_pass": "Забыли пароль?",
    "pareigomsLabel": "Должность в организации",
    "patvirtinta": "Подтвержденный",
    "patvirtinti_reg": "Подтвердить регистрацию",
    "paviesinta": "Опубликовано",
    "Paviešinta": "Опубликовано",
    "perziureti_rezultatus": "Посмотреть результаты",
    "pildytojuTipaiLabel": "Выбрать",
    "prenumeratos_label": "Срок действия подписки",
    "prisijungti": "Логин",
    "prisiminti": "Запомнить",
    "profilio_redagavimas": "Редактирование профиля",
    "profilis": "Профиль",
    "redaguoti": "Редактировать",
    "registruoti": "Зарегистрируйтесь",
    "registruotis": "Зарегистрируйтесь",
    "religijosLabel": "Вы верующий?",
    "rezultataiRezultatai": "Результаты",
    "rezultataiTendencijos": "Баллы",
    "rodoma": "Показаны:",
    "sekminga_reg": "Регистрация прошла успешно!",
    "sekminga_reg_txt1": "Мы активируем Ваш профиль или свяжемся с Вами в течение 3 рабочих дней. Если у Вас есть какие-либо вопросы, напишите нам на электронную почту diversity@diversity.lt",
    "slaptazodzio_priminimas": "Напомнить пароль",
    "slaptazodzio_priminimas_txt": "Для напоминания пароля, введите адрес электронной почты, который Вы используете для входа.",
    "statusas_label": "Статус",
    "sukurti_apklausa": "Создать опрос",
    "sukurti_imone": "Регистрация организации",
    "susipazinau_su_taisyklem": "Я ознакомился с политикой конфиденциальности и правилами.",
    "taip": "Да",
    "telNr": "Номер телефона",
    "tipas_label": "Тип формы",
    "trinti": "Delete",
    "vardas_pavarde": "Имя Фамилия",
    "visosImonesLabel": "Выберите организацию",
    "iveskite_nauja_pass": 'Введите новый пароль',
    "ExportTable": "Таблица",
    "Nepaviešinta": "Не опубликовано",
    "max3filtrai": "Выберите не более 3 фильтров",
    "diskriminacija": "Сотрудники, которым трудно распознать потенциал",
    "slaptazodisPakeistas":"Пароль изменен",
    "ivairove_patvirtintas_acc": "Пользователь проверен",
    "pp": 'Политика конфиденциальности',
    "pt": 'Условия использования',
    'iveskiteImoneMin': 'Неправильное название компании',
    "atliko": "Завершили опрос: {{kiek}}",
    "accComfirmedError": "Пользователь не подтвержден",
    "accValidToError": "Срок действия подписки истек",
    rezultatai: {
      title: "Оценка разнообразия и инклюзивности:",
      balai: "{{balas}} из 10",
      share: "Скачать сертификат",
    },
    "grafikai": {
      komentaras: "Комментарии",
      rodyti: "Показать",
      paslepti: "Спрятать"
    },
    amziaiText: ['18-34 года', '35-54 года', '55-74 года', '75 лет и старше', 'Не скажу'],
    issilavinimaiText: ['Начальное', 'Среднее', 'Высшее', 'Другое'],
    kompiuteriaiText: ['У меня есть персональный компьютер для работы', 'Я работаю на общих компьютерах', 'Я не работаю с компьютером'],
    laikaiText: ['до 1 года', '1-5 лет', '5-10 лет', 'Более 10 лет'],
    linkaiAdminText: ['Баллы', 'Результаты', 'Опросы', 'Организации'],
    linkaiNeAdminText: ['Баллы', 'Результаты', 'Опросы'],
    lytysText: ['Мужчина', 'Женщина', 'Другой', 'Не скажу'],
    negaliosText: ['Да', 'Нет', 'Не скажу'],
    LGBTText: ['Да', 'Нет', 'Не скажу'],
    orientacijosText: ['Асексуал', 'Бисексуал', 'Гомосексуал', 'Гетеросексуал', 'Другое', 'Не скажу'],
    pildytojuTipaiText: ['Опрос сотрудников', "Опрос для представителя организации", 'Общий результат'],
    pildytojuTipaiText2: ['Опрос сотрудников', "Опрос для представителя организации"],
    'Įmonės forma': "Опрос для представителя организации",
    'Darbuotojo forma': 'Опрос сотрудников',
    'All': 'Общий результат',
    tipas_text: ['Опрос сотрудников', 'Опрос для представителя организации'],
    padetysText: ['Женат/замужем', 'В партнерстве', 'Один', 'В разводе', 'Не скажу', 'Другое'],
    pareigomsText: ['Менеджер высшего уровня', 'Менеджер среднего звена', 'Менеджер первого уровня', 'Сотрудник/специалист', 'Другое'],
    religijosText: ['Да', 'Нет', 'Не знаю', 'Не скажу'],
    statusas_text: ['Приостановленный', 'Не опубликовано', 'Опубликовано'],
    menuAdmin: ['Организации', 'Редактор форм', 'Результаты'],
    prenumeratos_options: ['Один год', 'Неограниченный', 'Приостановленный'],
    metai: "Год",


    //30.1
    // lytysLabel  lytysText lytis
    'Vyras': 'Мужчина',
    'Moteris': 'Женщина',
    'Kita': 'Другой',
    'Nesakysiu': 'Не скажу',
    'Lytis': 'Пол',
    // amziusLabael amziaiText Amzius
    "Amzius": "Возраст",
    '18-34 metai': '18-34 года',
    '35-54 metai': '35-54 года',
    '55-74 metai': '55-74 года',
    '75 metai ar vyresnis': '75 лет и старше',
    'Nesakysiu': 'Не скажу',

    //Padetis padetysLabel padetysText:
    "Padetis": 'Семейное положение',
    'Vedęs/Ištekėjusi': 'Женат/замужем',
    'Partnerystėje': 'В партнерстве',
    'Vienas (-a)': 'Один',
    'Išsiskyręs (-usi)': 'В разводе',
    'Nesakysiu': 'Не скажу',
    'Kita': 'Другое',
    //Laiko laikaiLabel laikaiText: 
    'Kiek laiko': 'Как долго работает',
    'Iki 1 m.': 'до 1 года',
    '1-5 m.': '1-5 лет',
    '5-10 m.': '5-10 лет',
    'Virš 10 m.': 'Более 10 лет',
    //Pareigos   pareigomsLabel  pareigomsText:
    'Pareigos': 'Должность в организации',
    'Aukščiausio lygio vadovas/ė': 'Менеджер высшего уровня',
    'Vidutinio lygio vadovas/ė': 'Менеджер среднего звена',
    'Pirmojo lygio vadovas/ė': 'Менеджер первого уровня',
    'Darbuotojas/ specialistė': 'Сотрудник/специалист',
    'Kita': 'Другое',
    //Kompiuretis "kompiuteriaiLabel": "Ar darbo vietoje naudojasi asmeniniu kompiuteriu?" kompiuteriaiText,
    "Kompiuteris": "Используете ли Вы персональный компьютер для работы?",
    'Darbo reikmėms turiu asmeninį kompiuterį': 'У меня есть персональный компьютер для работы',
    'Dirbu prie bendro naudojimo kompiuterių': 'Я работаю на общих компьютерах',
    'Nedirbu su kompiuteriu': 'Я не работаю с компьютером',
    //Issilavinimas  issilavinimaiLabel issilavinimaiText:
    "Issilavinimas": "Образование",
    'Pradinis': 'Начальное',
    'Vidurinis': 'Среднее',
    'Aukštasis': 'Высшее',
    'Kita': 'Другое',
    //Religija religijosLabel   religijosText: 
    'Religija': 'Вы верующий?',
    'Taip': 'Да',
    'Ne': 'Нет',
    'Nežinau': 'Не знаю',
    'Nesakysiu': 'Не скажу',
    //Orientacija "orientacijosLabel", orientacijosText: 
    'Orientacija': 'Сексуальная ориентация',
    'LGBT': 'Вы принадлежите к сообществу ЛГБТ+',
    'Aseksualus': 'Асексуал',
    'Biseksualus': 'Бисексуал',
    'Homoseksualus': 'Гомосексуал',
    'Heteroseksualus': 'Гетеросексуал',
    'Kita': 'Другое',
    'Nesakysiu': 'Не скажу',
    //Negalia negaliosLabel negaliosText: ,
    'Negalia': 'Вы - человек с ограниченными возможностями здоровья/человек с инвалидностью?',
    'Taip': 'Да',
    'Ne': 'Нет',
    'Nesakysiu': 'Не скажу',
    'palyginus_su_vidurkiu': 'Оценка сравнивается со средней оценкой других компаний.',
    'atlikti_vertinima_label': "Провести опрос",
    'atsisiusti_visus': 'JPG',
    'atidaryti_visus': 'Открыть все',
    'paslepti_visus': 'Закрыть все',
    'is_10_galimu':'Из 10 возможных',


    //Taisyklės

    'pp1':'Последнее обновление: 31 января 2023',
    'pp2':'Это политика конфиденциальности инструмента оценки многообразия и инклюзивности в организациях (далее – Инструмент), распространяющаяся на обработку персональных данных зарегистрированных пользователей (далее – Вы). Ядром команды Инструмента являются сотрудники Хартии разнообразия Литвы  (регистрационный код 302877111, адрес ул. Лукишкю 5-527, Вильнюс). Ассоциация Хартии разнообразия Литвы является контролером данных, обрабатываемых для целей Инструмента (далее — Мы).',
'pp3':'Цель Инструмента — оценить состояние разнообразия и инклюзивности в Вашей команде. Для достижения этой цели мы должны обрабатывать персональные данные, которые Вы предоставляете при регистрации и использовании Инстрвумента. ',
'pp4':'При обработке ваших персональных данных мы соблюдаем требования Общего регламента по защите персональных данных (далее – GDPR), Закона о правовой защите персональных данных Литовской Республики, а также других правовых актов.',
'pp5':'Какие услуги предоставляются в Инструменте?',
'pp6':'Мы разрешаем Вам создать учетную запись (профиль) в Инструменте, в которой 	Вы указываете свое имя, фамилию, адрес электронной почты, представляемую 	организацию;',
'pp7':"Мы разрешаем Вам использовать анкеты в Инструменте, которыми Вы можете 	поделиться со своими сотрудниками и предложить им конфиденциально ответить 	на вопросы, представленные в Инструменте. Ответы сотрудников сохраняются, и 	Вы можете ознакомиться с обезличенными ответами в Инструменте;",
'pp8': 'В инструменте мы разрешаем использовать две анкеты — одна предназначена 	для представителя организации, другая — для сотрудников. Ответы на оба 	вопросника суммируются в общий балл и представлены таким образом, что вы 	можете оценить, что чувствуют люди, работающие в Вашей команде, каков их 	опыт и насколько подготовлена организация, которую Вы представляете, для 	обеспечения открытой и инклюзивной рабочей среды.',
'pp9': 'Какие данные мы собираем, храним и используем?',
'pp10': 'Для того, чтобы предоставить вышеуказанные услуги, Вы предоставляете, и мы храним следующую информацию:',
'pp11': 'Категория данных',
'pp12': 'Тип данных',
'pp13': 'Обязательные регистрационные данные (необходимы для завершения регистрации в Инструменте)',
'pp14':'Имя;',
'pp15':'Фамилия;',
'pp16':'Адрес электронной почты;',
'pp17':'Название представляемой организации',
'pp18':'Данные для входа',
'pp19':'Ваш уникальный ключ доступа, который создается при первой регистрации в Инструменте. Этот ключ доступа необходим для входа в Вашу учетную запись, обмена опросами с сотрудниками и просмотра обезличенных результатов опросов.',
'pp20':'Контактные данные (После того, как вы поделитесь ссылкой на анкету Инструмента с сотрудниками)',
'pp21':'Answers to survey questions.',
'pp22':'Политика паролей',
'pp23':'При создании учётной записи в Инструменте, Вы выбираете пароль для входа. Национальный центр кибербезопасности рекомендует использовать надежные пароли длиной не менее 12 символов и состоящих из прописных букв, строчных букв, цифр и специальных символов. Рекомендуется использовать уникальный пароль, который больше нигде не используется.',
'pp24':'Не разглашайте пароль своей учетной записи третьим лицам. Если Вы раскроете свой пароль или любую другую информацию об учетной записи другим лицам, Вы ответственны за все действия, предпринятые при использовании Вашей учетной записи.',
'pp25':'Ваш пароль является ключом к входу в учетную запись. Мы не собираем Ваши пароли. Войдя в свою учетную запись, Вы можете изменить и обновить свои данные в любое время. Если Вы забыли свой пароль, Вы можете создать новый.',
'pp26':'Для каких целей и на каком правовом основании мы обрабатываем Ваши персональные данные и как долго мы их храним?',
'pp27':'Цель и обстоятельства обработки данных',
'pp28':'Категории обрабатываемых данных',
'pp29':'Правовая основа для обработки данных',
'pp30':'Срок хранения данных',
'pp31':'Создание Вашего профиля и предоставление основных услуг Инструмента',
'pp32':'Обязательные и необязательные регистрационные данные',
'pp33':'Ключ входа',
'pp34':'Законный интерес (ст. 6(1) (f) GDPR) предоставлять и получать услуги',
'pp35':'Данные обрабатываются до тех пор, пока Ваша учетная запись активна, т.е. до тех пор, пока Вы не удалите ее.',
'pp36':'Анализ данных для повышения производительности Инструмента  и взаимодействия с пользователем',
'pp37':'Агрегированные и анонимные технические данные',
'pp38':'Агрегированные и анонимные обязательные и необязательные регистрационные данные',
'pp39':'Законный интерес (ст. 6(1) (f) GDPR) улучшить услуги, предлагаемые в  , и Вашу заинтересованность в Инструменте получении качественных услуг',
'pp40':'Анонимизированные (не идентифицирующие личность) персональные данные могут храниться в течение неограниченного периода времени',
'pp41':'Выявление и устранение технических уязвимостей в Инструменте и обеспечение требований безопасности',
'pp42':'Технические данные',
'pp43':'Другие категории данных в зависимости от характера инцидентов',
'pp44':'Законный интерес (ст. 6(1) (f) GDPR) обеспечить безопасность и предотвратить недобросовестную практику со стороны третьих лиц',
'pp45':'Данные обрабатываются до тех пор, пока не будет решена техническая проблема или инцидент безопасности, и в течение 1 года после окончания инцидента.',
'pp46':'Отвечать на запросы и просьбы пользователей Инструменте',
'pp47':'Данные корреспонденции или связи',
'pp48':'Законный интерес (ст. 6(1)(f) GDPR) обеспечить непрерывность услуг и качество предоставления',
'pp49':'Юридическое обязательство (ст. 6(1) (c) GDPR) осуществлять права субъектов данных в соответствии с GDPR',
'pp50':'Данные хранятся в течение 6 месяцев с момента последнего ответа на Ваш запрос',
'pp51':'По истечении указанного срока хранения данных, мы удалим Ваши данные, за исключением случаев, когда это необходимо для выполнения юридических обязательств (например, для ответа на юридические претензии) и нормативных требований (например, для предоставления данных компетентным органам на законных основаниях).',
'pp52':'С кем мы можем поделиться Вашими персональными данными?',
'pp53':'Мы не будем передавать Ваши персональные данные третьим лицам, если это не необходимо или не требуется по закону для обеспечения предоставляемых нами услуг. По этим причинам Ваши персональные данные могут быть переданы следующим группам получателей:',
'pp54':'Компаниям, предоставляющим техническую поддержку и услуги технической инфраструктуры (например, хостинг и хранение данных) Инструменту;',
'pp55':'Facebook и Google, которые предоставляют услуги плагинов для входа. Эти компании могут обрабатывать Ваши данные в третьих странах, поэтому мы рекомендуем Вам ознакомиться с информацией, предоставляемой следующими компаниями, и условиями стандартных договоров: ',
'pp56':'Facebook',
'pp57':'Google',
'pp58':'Компетентным государственным органам, но только в том случае, если этого требует закон или судебные решения;',
'pp59':'Мы можем делиться агрегированными и анонимными (что не позволяет нам идентифицировать вашу личность) со спонсорами, указанными в этом этом Инструменте и более широкой аудиторией, информируя их об услугах, предоставляемых в Инструменте.',
'pp60':'Ваши права',
'pp61':'При обработке персональных данных мы гарантируем соблюдением ваших прав в соответствии с GDPR и Законом о правовой защите персональных данных Литовской Республики. У Вас есть следующие права:',
'pp62':'исправлять или дополнять, корректировать ',
'pp63':'неверные или неточные персональные данные о Вас. Вы можете сделать это в разделе «Профиль» Инструмента;',
'pp64':'удалить свою учетную запись ',
'pp65':'и, таким образом, уничтожить Ваши персональные данные. Если Вы удалите свою учетную запись, Ваши личные данные будут удалены из системы, у нас их больше не будет и мы не сможем их восстановить. В этом случае Вы больше не сможете пользоваться Инструментом, и мы не поможем Вашей организации оценить ее статус в сфере разнообразия и инклюзивности;',
'pp66':'ознакомиться с вашими данными, ',
'pp67':'которые мы собрали, другими словами, предоставить копию данных, которые мы храним о Вас. Вы можете увидеть свои регистрационные данные в разделе «Профиль» Инструмента;',
'pp68':'запросить ограничение обработки данных ',
'pp69':'– если  Вы считаете, что мы 	обрабатываем  Ваши данные незаконно или что данные, которые мы храним о  Вас, 	неверны,  Вы можете потребовать, чтобы мы временно не использовали  Ваши 	данные. В таком случае, до тех пор, пока не будет принято решение по данному 	требованию, мы можем только хранить данные, в то время как другие действия с ними потребуют Вашего отдельного согласия;',
'pp70':'запрос на возражение против обработки данных ',
'pp71':'– если Вы считаете, что есть конкретные причины, по которым мы не должны обрабатывать Ваши данные, Вы также можете связаться с нами по этим причинам и потребовать, чтобы Ваши данные не обрабатывались;',
'pp72':'подать жалобу в надзорный орган ',
'pp73':'– если Вы считаете, что мы используем Ваши данные незаконно, Вы также можете подать жалобу в орган по защите данных. В Литве это  Государственная инспекция по защите данных.',
'pp74':'Вы можете подать запрос на осуществление своих прав и связаться с нами по электронной почте diversity@diversity.lt',
'pp75':'Обновления политики конфиденциальности',
'pp76':'Мы оставляем за собой право изменять политику конфиденциальности, в соответствии с текущей ситуацией. После внесения изменений мы укажем дату обновления в верхней части этой страницы. Мы рекомендуем Вам периодически просматривать нашу политику безопасности, чтобы Вы знали, как мы защищаем и обрабатываем Ваши персональные данные.',
'pp77':'I certify that I have all rights to create an account on behalf of an organisation and that all information I will provide is correct. The Company assumes full responsibility for the accuracy of the information provided.',


  }
}